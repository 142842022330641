import { useEffect } from 'react'
import loadable from '@loadable/component'

import { Routes, Route, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import { setUser } from './features/auth/slice'
import RequireAuth from './components/RequireAuth'
import AuthRoute from './components/AuthRoute'

import { getProfileAsync } from './features/profile/slice'
import { selectUserProfile } from './features/profile/selectors'
import { selectSubscriptionExpiration } from './features/subscription/selectors'
import EmployeeSalaries from './features/employees/EmployeeSalaries'
import EmployeeBonuses from './features/employees/EmployeeBonuses'
import EmployeeVacations from './features/employees/EmployeeVacations'

//Offers Pages
const SupplierOffer = loadable(() => import('./features/offers/SupplierOffer'))
const ViewOffer = loadable(() => import('./features/offers/ViewOffer'))
const ViewOfferRequest = loadable(() =>
  import('./features/offers/ViewOfferRequest')
)
const UpsertOffer = loadable(() => import('./features/offers/UpsertOffer'))
const ListOffers = loadable(() => import('./features/offers/List'))

// installments
const ListInstallmentUnits = loadable(() =>
  import('./features/installment/List')
)
const UpsertUnit = loadable(() => import('./features/installment/UpsertUnit'))
const UnitView = loadable(() => import('./features/installment/UnitView'))

// Employees
const ListEmployees = loadable(() => import('./features/employees/List'))
const UpsertEmployee = loadable(() =>
  import('./features/employees/UpsertEmployee')
)
const EmployeeView = loadable(() => import('./features/employees/EmployeeView'))

const ProjectDashboard = loadable(() =>
  import('./features/dashboard/ProjectDashboard')
)

const Khazna = loadable(() => import('./features/khazna/Khazna'))

const Login = loadable(() => import('./features/auth/Login'))
const Logout = loadable(() => import('./features/auth/Logout'))
const Register = loadable(() => import('./features/auth/Register'))
const ResetPassword = loadable(() => import('./features/auth/ResetPassword'))

const ListClients = loadable(() => import('./features/client/List'))
const ViewDetails = loadable(() => import('./features/client/ViewDetails'))

const ListProjects = loadable(() => import('./features/project/List'))
const ViewProject = loadable(() => import('./features/project/View'))

const UpsertService = loadable(() => import('./features/service/UpsertService'))

const ListPayments = loadable(() => import('./features/payment/List'))
const ListJobs = loadable(() => import('./features/job/List'))
const ListOrders = loadable(() => import('./features/order/List'))
const ViewOrder = loadable(() => import('./features/order/ViewOrder'))
const ListPayouts = loadable(() => import('./features/payout/List'))
const ListSuppliers = loadable(() => import('./features/supplier/List'))
const ListServices = loadable(() => import('./features/service/List'))
const ListContractors = loadable(() => import('./features/contractor/List'))
const ListItems = loadable(() => import('./features/item/List'))
const ListProjectServices = loadable(() =>
  import('./features/projectService/List')
)

const ViewProjectService = loadable(() =>
  import('./features/projectService/View')
)
const ViewProfile = loadable(() => import('./features/profile/View'))

const ClientAccountStatment = loadable(() =>
  import('./features/client/clientAccountStatment')
)
const SupplierAccountStatment = loadable(() =>
  import('./features/supplier/supplierAccountStatment')
)
const ProjectAccountStatment = loadable(() =>
  import('./features/project/ProjectAccountStatment')
)
const ContractorPayouts = loadable(() =>
  import('./features/contractor/ContractorPayouts')
)
const SubscriptionDetails = loadable(() =>
  import('./features/subscription/SubscriptionDetails')
)
const SubscriptionRenew = loadable(() =>
  import('./features/subscription/SubscriptionRenew')
)
const SubscriptionRequest = loadable(() =>
  import('./features/subscription/SubscriptionRequest')
)
const SubscriptionExpiration = loadable(() =>
  import('./features/subscription/SubscriptionExpiration')
)

const SubscriptionHistoryTable = loadable(() =>
  import('./features/subscription/SubscriptionHistoryTable')
)
const SupplierPayoutsList = loadable(() =>
  import('./features/supplier/SupplierPayoutsList')
)
const ContractorPayoutsList = loadable(() =>
  import('./features/contractor/ContractorPayoutsList')
)
const SetPassword = loadable(() => import('./features/auth/SetPassword'))
const UpsertOrder = loadable(() => import('./features/order/UpsertOrder'))
const ProjectGallery = loadable(() =>
  import('./features/project/ProjectGallery')
)
const AddProjectImage = loadable(() =>
  import('./features/project/AddProjectImage')
)

function App() {
  const userProfile = useSelector(selectUserProfile)
  const subscriptionExpiration = useSelector(selectSubscriptionExpiration)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setUser(user))
        dispatch(getProfileAsync())
      } else {
        dispatch(setUser(null))
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (subscriptionExpiration) {
      navigate('/subscription-expiration')
    }
  }, [navigate, subscriptionExpiration])

  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route exact path="/logout" element={<Logout />} />
        <Route path="/clients">
          <Route path="" element={<ListClients />} />
          <Route path=":clientID" element={<ViewDetails />} />
          <Route
            path=":clientID/account-statement"
            element={<ClientAccountStatment />}
          />
        </Route>
        <Route path="/contractors">
          <Route path="" element={<ListContractors />} />
          <Route
            path=":contractorID/contractor-payouts-list"
            element={<ContractorPayoutsList />}
          />
        </Route>
        <Route path="/suppliers">
          <Route path="" element={<ListSuppliers />} />
          <Route
            path=":supplierID/supplier-payouts-list"
            element={<SupplierPayoutsList />}
          />
          <Route
            path=":supplierID/account_statment"
            element={<SupplierAccountStatment />}
          />
        </Route>
        <Route path="/services">
          <Route path="" element={<ListServices />} />
          <Route path=":id" element={<UpsertService />} />
        </Route>
        <Route path="/items">
          <Route path="" element={<ListItems />} />
        </Route>
        <Route path="/khazna">
          <Route path="" element={<Khazna />} />
        </Route>
        <Route path="/offers">
          <Route path="" element={<ListOffers />} />
          <Route path="offer/:offerID" element={<ViewOffer />} />
          <Route path=":offerID/offer-request" element={<ViewOfferRequest />} />
          <Route path=":id" element={<UpsertOffer />} />
        </Route>
        <Route path="/units">
          <Route path="" element={<ListInstallmentUnits />} />
          <Route path="unit/:unitId" element={<UnitView />} />
          <Route path=":id" element={<UpsertUnit />} />
        </Route>
        <Route path="/employees">
          <Route path="" element={<ListEmployees />} />
          <Route path="employee/:employeeId" element={<EmployeeView />} />
          <Route
            path="employee/:employeeId/employee-salaries"
            element={<EmployeeSalaries />}
          />
          <Route
            path="employee/:employeeId/employee-bonuses"
            element={<EmployeeBonuses />}
          />
          <Route
            path="employee/:employeeId/employee-vacations"
            element={<EmployeeVacations />}
          />

          <Route path=":id" element={<UpsertEmployee />} />
        </Route>
        <Route path="/profile" element={<ViewProfile />} />
        <Route path="/profile" element={<ViewProfile />} />
        <Route
          path="/subscription-expiration"
          element={<SubscriptionExpiration />}
        />
        <Route path="/subscription-request" element={<SubscriptionRequest />} />
        <Route path="/subscription-details" element={<SubscriptionDetails />} />
        <Route
          path="/subscription-history"
          element={<SubscriptionHistoryTable />}
        />
        {userProfile.role !== 'user' && (
          <Route>
            <Route path="/subscription-renew" element={<SubscriptionRenew />} />
          </Route>
        )}

        <Route path="/">
          <Route path="" element={<ProjectDashboard />} />
          <Route path="projects" element={<ListProjects />} />
          <Route path=":projectID" element={<ViewProject />} />
          <Route
            path=":projectID/project-account-statement"
            element={<ProjectAccountStatment />}
          />
          <Route
            path=":projectID/project-gallery"
            element={<ProjectGallery />}
          />
          <Route
            path=":projectID/new-project-image"
            element={<AddProjectImage />}
          />

          <Route path=":projectID/payments" element={<ListPayments />} />
          <Route
            path=":projectID/project-services"
            element={<ListProjectServices />}
          />

          <Route path=":projectID/jobs" element={<ListJobs />} />

          <Route path=":projectID/orders">
            <Route path="" element={<ListOrders />} />
            <Route path=":id" element={<UpsertOrder />} />
            <Route path=":orderID/items" element={<ViewOrder />} />
          </Route>
          <Route path=":projectID/payouts" element={<ListPayouts />} />
          <Route
            path=":projectID/contractor-payouts"
            element={<ContractorPayouts />}
          />
          <Route
            path=":projectID/:projectServiceID"
            element={<ViewProjectService />}
          />
        </Route>
      </Route>

      <Route
        path="/offer/:id"
        element={
          <AuthRoute>
            <SupplierOffer />
          </AuthRoute>
        }
      />
      <Route
        path="/login"
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route
        path="register"
        element={
          <AuthRoute>
            <Register />
          </AuthRoute>
        }
      />
      <Route
        path="reset-password"
        element={
          <AuthRoute>
            <ResetPassword />
          </AuthRoute>
        }
      />
      <Route
        path="invite"
        element={
          <AuthRoute>
            <SetPassword />
          </AuthRoute>
        }
      />
    </Routes>
  )
}

export default App
