import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { COLORS } from '../../constants'

export default function ActionButton ({ children, onClick, ...props }) {
  return (
    <Button
      // variant="contained"
      fullWidth
      startIcon={<AddIcon  />}
      onClick={onClick}
      // color="primary"
      style={{
        backgroundColor: COLORS.primary,
        borderRadius: '3px',
        float: 'left',
        color: COLORS.white
      }}
      sx={{
        height: { xs: '38px', md: '44px' },
        width: '158px',
        fontSize: '14px',
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
