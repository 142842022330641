const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '.']
const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.']

export const convertToEnglishNumber = (num) => {
  return num
    .split('')
    .map(
      (c) =>
        englishNumbers[arabicNumbers.indexOf(c)] ||
        (englishNumbers.indexOf(c) > -1 && c) ||
        ''
    )
    .join('')
}
