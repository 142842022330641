import { useState } from 'react'
import { makeStyles } from '@mui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'

import Appbar from './Appbar'
import Sidebar from './Sidebar'
import { selectCurrentUser } from '../features/auth/selectors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}))

export default function AppLayout ({ children }) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

    // The correct way is to add it to the Outlet but let's fix it later
    const currentUser = useSelector(selectCurrentUser)
    if (!currentUser) {
      return (
        <>
          <Toaster />
          {children}
        </>
      )
    }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Appbar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      <Toaster />
      <Box
        component="main"
        className={classes.content}
        sx={{
          overflowX: 'hidden',
          // backgroundColor: { lg: 'background.third'},
          backgroundColor: 'background.third'

        }}
      >
        <div className={classes.toolbar} />
        {children}
      </Box>
    </div>
  )
}
