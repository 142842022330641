import Typography from '@mui/material/Typography'

export default function ModalLabelTypography ({ children, ...props }) {
  return (
    <Typography
      sx={{
        color: 'text.darkGray',
        fontSize: '14px',
        fontWeight: '600'
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
