// USE THIS
import MuiTextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const TextField = styled(MuiTextField)(({ theme }) => ({
  height: '48px',
  color: 'text.black',
  [theme.breakpoints.down('md')]: {
    padding: '0 10px',
    paddingTop: '0px'
  },
  '& .MuiInputBase-input': {
    color: 'black',
    fontSize: '14px',
    '&::before' : {
      borderBottom : 'none'
    },
    '&::placeholder': {
      opacity: '1',
      color: '#9A9A9A',
      [theme.breakpoints.up('sm')]: {
        opacity: '1',
        color: 'text.third'
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}))
export default function StyledTextField (props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        disableUnderline: true
      }}
      variant="standard"
      sx={{
        borderRadius: '3px',
        backgroundColor: 'background.secondary',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      {...props}
    />
  )
}
