export const STATE_IDLE = 'idle'
export const STATE_LOADING = 'loading'
export const STATE_SUCCEEDED = 'succeeded'
export const STATE_FAILED = 'failed'

export const COLORS = {
  primary: '#F28705', // orange
  secondary: '#195C92',
  third: '#9A9A9A', // gray
  primaryBackground: '#EEEEEE',
  secondaryBackground: '#F9F9F9',
  headerTableBackground: '#F5F5F5',
  background: '#FFFFFF', // white background
  white: '#EEF2F6',
  buttonColor: '#121826',
  buttonBackground: '#768AE4',
  menuBackground: '#00000029',
  export: '#4caf50', // green
  black: '#000000',
  green: '#26AF3D',
  blue: '#195C92',
  gray: '#F1F1F1',
  darkGray: '#5C5C5C'
}

export const PROJECT_TYPES = {
  SUPERVISION: 'supervision'
}
