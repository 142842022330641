import { COLORS } from '../constants'
import { convertToEnglishNumber } from '../utils/number'
import StyledTextField from './StyledTextField'

export default function NumberInputField ({ name, value, onChange, ...rest }) {
  return (
    <StyledTextField
      value={value}
      style={{ color: COLORS.black }}
      onChange={(e) => onChange(name, convertToEnglishNumber(e.target.value))}
      {...rest}
    />
  )
}
