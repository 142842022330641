import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { Grid, Box, Typography } from '@mui/material'
import ActionButton from '../project/ActionButton'
import CustomTableCell from '../../components/TabelCell'
import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import MenuItem from '@mui/material/MenuItem'

import Menu from '@mui/material/Menu'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { COLORS } from '../../constants'
import UpsertEmployeeBonuses from './UpsertEmployeeBonuses'
import DeleteModal from '../../components/DeleteModal'
import AddButton from '../../components/AddButton'
import PageHeader from '../../components/PageHeader'

const tableHead = [
  {
    id: 'sequence',
    label: 'م',
    minWidth: 5,
    align: 'right'
  },
  { id: 'date', label: 'التاريخ', minWidth: 100, align: 'right' },
  { id: 'amount', label: '  قيمة الحافز', minWidth: 100, align: 'right' },

  {
    id: 'notes',
    label: ' ملاحظات',
    minWidth: 120,
    align: 'right'
  }
]

export default function EmployeeBonuses() {
  const [isEdit, setIsEdit] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }

  const handleAddBonus = () => {
    handleOpen()
    setIsEdit(false)
  }

  const handleEditBonus = () => {
    handleOpen()
    setIsEdit(true)
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleOpenDeleteModal = () => setOpenDeleteModal(true)
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  return (
    <Box
      sx={{
        bgcolor: { lg: 'background.primary' },
        borderRadius: { lg: '8px' },
        minHeight: '152px',
        pt: { xs: '0px', lg: '20px' },
        pb: { xs: '16px', lg: '24px' },
        px: { xs: '16px', lg: '24px' },
        mb: { xs: '16px', lg: '24px' }
      }}
    >
      <Grid
        container
        marginBottom={'20px'}
        sx={{ display: { xs: 'none', lg: 'flex' } }}
      >
        <Grid item xs={8}>
          <Typography
            sx={{
              color: 'text.black',
              fontWeight: '600',
              fontSize: '16px',
              pb: '20px'
            }}
          >
            الحوافز والمكافآت
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ActionButton
            sx={{
              width: '125px',
              height: '40px'
            }}
            onClick={() => handleAddBonus()}
          >
            {' '}
            أضف حافز
          </ActionButton>
        </Grid>
      </Grid>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <PageHeader title={'الحوافز والمكافآت '} backPath={'/employees'} />
      </Box>

      <Paper
        sx={{
          boxShadow: '0 0',
          border: '1px solid #F5F5F5',
          borderBottom: 'none',
          mb: { xs: '80px', lg: '0px' }
        }}
      >
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              overflow: 'scroll',
              width: { xs: 'max-content', sm: '100%' }
            }}
          >
            <TableHead>
              <TableRow>
                {tableHead.map((column) => (
                  <TableCell
                    column={column}
                    key={column.id}
                    style={{
                      textAlign: 'right',
                      height: '48px'
                    }}
                    sx={{
                      width: { md: column.minWidth },
                      height: '48px',
                      bgcolor: 'background.gray',
                      color: 'text.black',
                      py: { xs: 1, md: '15px' },
                      px: { xs: '20px', md: '24px' },
                      fontSize: '14px',
                      fontWeight: '600',
                      borderBottom: 'none'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow position={'relative'} hover>
                <CustomTableCell>1</CustomTableCell>
                <CustomTableCell>1/5/2024</CustomTableCell>
                <CustomTableCell>1000</CustomTableCell>
                <CustomTableCell
                  style={{
                    textAlign: 'right',
                    maxWidth: '250px',
                    width: '250px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography>notes</Typography>
                    <IconButton
                      aria-label="edit-delete"
                      aria-controls="edit-delete"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuOpen(e)}
                      sx={{ color: 'text.third', justifySelf: 'flex-end' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </CustomTableCell>
              </TableRow>

              <TableRow position={'relative'} hover>
                <CustomTableCell>2</CustomTableCell>
                <CustomTableCell>1/6/2024</CustomTableCell>

                <CustomTableCell>3000</CustomTableCell>
                <CustomTableCell
                  style={{
                    textAlign: 'right',
                    maxWidth: '250px',
                    width: '250px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography>notes</Typography>
                    <IconButton
                      aria-label="edit-delete"
                      aria-controls="edit-delete"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuOpen(e)}
                      sx={{ color: 'text.third', justifySelf: 'flex-end' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </CustomTableCell>
              </TableRow>

              <TableRow position={'relative'} hover>
                <CustomTableCell>3</CustomTableCell>
                <CustomTableCell>1/7/2024</CustomTableCell>
                <CustomTableCell>1500</CustomTableCell>
                <CustomTableCell
                  style={{
                    textAlign: 'right',
                    maxWidth: '250px',
                    width: '250px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography>notes</Typography>
                    <IconButton
                      aria-label="edit-delete"
                      aria-controls="edit-delete"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuOpen(e)}
                      sx={{ color: 'text.third', justifySelf: 'flex-end' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </CustomTableCell>
              </TableRow>

              <Menu
                anchorEl={anchorEl}
                elevation={0}
                id="edit-delete"
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    width: '130px',
                    boxShadow: '0px 0px 2px #E2E2E2'
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '11px',
                    height: '40px'
                  }}
                  onClick={() => handleEditBonus()}
                >
                  <EditIcon
                    sx={{
                      color: '#CACACA',
                      width: '18px',
                      height: '18px'
                    }}
                  />

                  <Typography style={{ fontSize: '14px', color: COLORS.black }}>
                    تعديل
                  </Typography>
                </MenuItem>

                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '11px',
                    height: '40px'
                  }}
                  onClick={() => handleOpenDeleteModal()}
                >
                  <DeleteIcon
                    sx={{
                      color: '#CACACA',
                      width: '18px',
                      height: '18px'
                    }}
                  />
                  <Typography style={{ fontSize: '14px', color: COLORS.black }}>
                    حذف
                  </Typography>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <UpsertEmployeeBonuses
        handleClose={handleClose}
        open={open}
        isEditModal={isEdit}
      />

      <DeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        title={'حذف حافز'}
        warningMessage={'هل تريد بالفعل حذف هذا الحافز ؟'}
      />

      <AddButton showAddModal={handleAddBonus} label={'add bonus'} />
    </Box>
  )
}
