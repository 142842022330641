import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'

import { styled } from '@mui/system'

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'Cairo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px;
    border-radius: 3px;
    color: ${theme.palette.mode === 'dark' ? '' : '#000'};
    background: ${theme.palette.mode === 'dark' ? '' : '#EEEEEE'};
    border: 1px solid ${theme.palette.mode === 'dark' ? '' : '#FFF'};
    resize: none;
    &:hover {
      border-color: '#FFF';
    }
  
    &:focus {
      border-color: '#FFF';
    }
  
    &:focus-visible {
      outline: 0;
    }
  `
)

export default function TextArea(props) {
  return <TextareaAutosize {...props} />
}
