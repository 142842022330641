import Button from '@mui/material/Button'

export default function ConfirmButton ({ label, onClick, ...rest }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        backgroundColor: 'text.secondary',
        color: 'text.white',
        borderRadius: '3px',
        width: { xs: '145px', sm: '180px' },
        height: '40px',
        boxShadow: '0 0',
        '&:hover': {
          backgroundColor: 'text.secondary'
        }
      }}
      {...rest}
    >
      {label}
    </Button>
  )
}
