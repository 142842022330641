import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import StyledTextField from './StyledTextField'

export default function CustomDatePicker({ value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        inputStyle={{ textAlign: 'center' }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            style={{
              textAlign: 'left'
            }}
          />
        )}
        inputFormat="yyyy/MM/dd"
        InputProps={{
          disableUnderline: true
        }}
     
      />
    </LocalizationProvider>
  )
}
