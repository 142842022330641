import ArrowForward from '@mui/icons-material/ArrowForward'
import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../constants'

export default function PageHeader ({ title, backPath }) {
  const navigate = useNavigate()

  return (
    <Grid
      display="flex"
      flexDirection="row"
      alignItems={'center'}
      sx={{
        fontSize: { xs: '16px', md: '18px' },
        color: 'text.black',
        fontWeight: '600',
        marginTop: { xs: '16px', md: '24px' },
        marginBottom: { xs: '16px', md: '26px' },
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline'
        }
      }}
      onClick={() => navigate(backPath)}
    >
      <ArrowForward
        // sx={{ fontSize: { xs: '16px', md: '20px' } }}
        style={{ color: COLORS.primary, marginLeft: '5px' }}
      />
      <Typography
        variant="h6"
        component="span"
        sx={{ fontWeight: '600', fontSize: { xs: '16px', md: '18px' } }}
      >
        {title}
      </Typography>
    </Grid>
  )
}
