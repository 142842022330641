import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import { COLORS } from './../constants'
import WarningIcon from '@mui/icons-material/Warning'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '343px', md: '428px' },
  bgcolor: COLORS.background,
  borderRadius: { xs: '5px', md: '10px' },
  boxShadow: 24,
  p: { xs: '16px', sm: '20px', md: '24px' }
}

export default function DeleteModal ({
  open,
  handleClose,
  handleDelete,
  title,
  subtitle,
  warningMessage
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                {title && (
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', md: '18px' },
                      color: 'text.secondary',
                      textAlign: 'center'
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', md: '18px' },
                      color: 'text.secondary'
                    }}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant={'span'}
                  sx={{ display: 'flex', alignSelf: 'flex-start' }}
                >
                  <WarningIcon
                    sx={{
                      width: '28px',
                      height: '30px',
                      color: 'text.secondary',
                      pr: '10px'
                    }}
                  />
                </Typography>
                <Typography
                  variant={'span'}
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    color: 'text.black',
                    lineHeight: '1.75'
                  }}
                >
                  {warningMessage}
                </Typography>
              </Grid>

              <Grid
                item
                container
                direction={'row'}
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                marginTop={'8px'}
              >
                <Typography
                  onClick={handleDelete}
                  sx={{
                    '&:hover': { cursor: 'pointer' },
                    color: 'text.secondary',
                    fontSize: '16px',
                    pr: '40px'
                  }}
                >
                  حذف
                </Typography>
                <Typography
                  onClick={handleClose}
                  sx={{
                    '&:hover': { cursor: 'pointer' },
                    color: 'text.secondary',
                    fontSize: '16px'
                  }}
                >
                  إلغاء
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
