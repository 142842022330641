import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { COLORS } from '../../constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ModalHeaderTypography from '../../components/ModalHeaderTypography'
import ModalLabelTypography from '../../components/ModalLabelTypography'
import ConfirmButton from '../../components/ConfirmButton'
import CancelButton from '../../components/CancelButton'
import DatePicker from '../../components/DatePicker'
import NumberInputField from '../../components/NumberInputField'
import TextArea from '../../components/TextArea'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '343px', md: '428px' },
  bgcolor: COLORS.background,
  borderRadius: '5px',
  boxShadow: 24,
  p: { xs: '16px', sm: '20px', md: '24px' }
}

export default function UpsertEmployeeBonuses({
  handleClose,
  open,
  isEditModal,
  bonus
}) {
  const [bonusData, setBonusData] = useState('')

  useEffect(() => {
    isEditModal
      ? setBonusData(bonus)
      : setBonusData({
          bonusDate: new Date(),
          amount: '',
          note: ''
        })
  }, [bonus, isEditModal])

  const onInputChange = useCallback(
    (prop, value) => {
      setBonusData({
        ...bonusData,
        [prop]: value
      })
    },
    [bonusData]
  )

  const title = useMemo(() => (isEditModal ? ' تعديل حافز ' : ' إضافة حافز '))

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style} position={'relative'}>
          <Box
            position={'absolute'}
            top={'24px'}
            right={'20px'}
            onClick={() => handleClose()}
          >
            <CloseIcon sx={{ color: 'text.third', cursor: 'pointer' }} />
          </Box>

          <Grid container direction={'column'} spacing={{ xs: '12px', md: 2 }}>
            <Grid item textAlign={'center'} marginBottom={1}>
              <ModalHeaderTypography>{title}</ModalHeaderTypography>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <ModalLabelTypography> التاريخ </ModalLabelTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: 'black'
                  }}
                >
                  <DatePicker
                    value={bonusData?.bonusDate}
                    onChange={(newValue) =>
                      onInputChange('bonusDate', newValue)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <ModalLabelTypography> قيمة الحافز</ModalLabelTypography>
                </Grid>
                <Grid item xs={12}>
                  <NumberInputField
                    placeholder="  أدخل قيمة الحافز"
                    name={'amount'}
                    value={bonusData?.amount}
                    onChange={onInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <ModalLabelTypography>ملاحظات </ModalLabelTypography>
                </Grid>
                <Grid item xs={12}>
                  <TextArea
                    minRows={5}
                    placeholder=" أدخل الملاحظات ..."
                    value={bonusData?.notes}
                    onChange={(e) => onInputChange('notes', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              marginTop={{
                xs: '4px',
                md: '8px',
                display: 'flex',
                gap: '20px'
              }}
            >
              <ConfirmButton label={isEditModal ? 'تعديل ' : 'إضافة'} />
              <CancelButton label={'إلغاء'} />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}
