import Button from '@mui/material/Button'

export default function CancelButton ({ label, onClick, ...rest }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        fontSize: { xs: '14px', sm: '16px' },
        backgroundColor: '#E8E8E8',
        color: '#7E7E7E',
        borderRadius: '3px',
        width: { xs: '145px', sm: '180px' },
        height: '40px',
        boxShadow: '0 0',
        border: '1px solid #E8E8E8',
        '&:hover': {
          backgroundColor: '#E8E8E8',
          color: '#7E7E7E',
        },
                
      }}
      {...rest}
    >
      {label}
    </Button>
  )
}
