import TableCell from '@mui/material/TableCell'

export default function CustomTableCell (props) {
  const { children, align = 'right', sx: overrideSx, ...rest } = props
  return (
    <TableCell
      style={{
        textAlign: align
      }}
      sx={{
        bgcolor: 'background.primary',
        color: 'text.black',
        borderBottom: '1px solid #F5F5F5',
        py: { xs: '4px', md: '13px' },
        pl: { xs: '20px', md: '24px' },
        pr: { xs: 1, md: 2 },
        fontSize: '14px',
        ...overrideSx
      }}
      {...rest}
    >
      {children}
    </TableCell>
  )
}
