import { createTheme, ThemeProvider } from '@mui/material/styles'
// import { create } from "jss";
// import rtl from "jss-rtl";
// import { StylesProvider, jssPreset } from "@mui/styles";
import { COLORS } from '../constants'

import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin]
})

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Cairo, sans-serif'
  },
  palette: {
    secondary: {
      main: COLORS.primary
    },
    text: {
      secondary: COLORS.primary, // Orange
      primary: COLORS.secondary, // blue
      third: COLORS.third, // grey
      white: COLORS.white,
      black: COLORS.black,
      gray: COLORS.gray,
      darkGray: COLORS.darkGray
    },
    background: {
      primary: COLORS.background, // white
      secondary: COLORS.primaryBackground, // Grey
      third: COLORS.secondaryBackground,
      gray: COLORS.headerTableBackground
    }
  }
})

// const jss = create({
//   plugins: [...jssPreset().plugins, rtl()],
// });

export default function RTL ({ children }) {
  return (
    <ThemeProvider theme={theme}>
      {/* <StylesProvider jss={jss}> */}
      <CacheProvider value={cacheRtl}>{children}</CacheProvider>
      {/* </StylesProvider> */}
    </ThemeProvider>
  )
}
