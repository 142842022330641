import { styled } from '@mui/material/styles'
import MuiIconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import { COLORS } from '../constants'

const IconButton = styled(MuiIconButton)(({ theme, open }) => ({
  display: 'none',
  position: 'fixed',
  top: '90%',
  right: '16px',
  zIndex: '1000',
  backgroundColor: COLORS.primary,
  color: '#fff',
  minWidth: '52px',
  height: '52px',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ...(open && {
    zIndex: '-10'
  }),
  '&:hover': {
    backgroundColor: COLORS.primary,
    opacity: '0.7'
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.26)'
  }
}))
export default function AddButton ({ disabled, label, showAddModal, open }) {
  return (
    <IconButton
      disabled={disabled}
      aria-label={label}
      onClick={showAddModal}
      open={open}
    >
      <AddIcon />
    </IconButton>
  )
}
